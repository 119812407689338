import React, { Fragment, Component } from 'react';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Label from 'reactstrap/lib/Label';
import Input from 'reactstrap/lib/Input';
import { VisitorModel } from 'client/data/models/visitor';
import { ENTER_KEY_CODE, ESC_KEY_CODE } from 'client/site-modules/shared/constants/key-codes';
import { randomInt } from 'client/utils/seed-randomizers';
import { Button } from 'reactstrap';
import { validation } from 'site-modules/shared/components/form-validation/validation';
import { ErrorFieldCopy } from 'site-modules/shared/components/lead-form/error-field-copy/error-field-copy';
import { TrackingConstant } from 'client/tracking/constant';

export const propTypes = {
  onZipChange: PropTypes.func,
  onValidate: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  wrapperClasses: PropTypes.string,
  labelClasses: PropTypes.string,
  inputClasses: PropTypes.string,
  inputAriaLabel: PropTypes.string,
  facetName: PropTypes.string,
  inputSize: PropTypes.string,
  getTracking: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      'data-tracking-id': PropTypes.string,
      'data-tracking-value': PropTypes.string,
    }),
  ]),
  forceEmptyZip: PropTypes.bool,
  isUpdateZipOnChange: PropTypes.bool,
  noUpdateOnBlur: PropTypes.bool,
  isLocationAddressZip: PropTypes.bool,
  onZipChangeInProgress: PropTypes.func,
  trackingCbOnZipChange: PropTypes.func,
  onZipFocus: PropTypes.func,
  fireTrackingZipChange: PropTypes.func,
  btnColor: PropTypes.string,
  btnClassname: PropTypes.string,
  btnTrackingId: PropTypes.string,
  id: PropTypes.string,
  inputComponent: PropTypes.elementType,
  inputProps: PropTypes.shape({}),
  onInputChange: PropTypes.func,
  zipCode: PropTypes.string,
  goText: PropTypes.string,
  withGoBtn: PropTypes.bool,
  withErrorText: PropTypes.bool,
  errorTextClasses: PropTypes.string,
};

export const defaultProps = {
  onZipChange: null,
  onValidate: validation.validateZip,
  label: '',
  wrapperClasses: '',
  labelClasses: '',
  inputClasses: '',
  placeholder: '',
  facetName: '',
  inputSize: 'sm',
  getTracking: null,
  forceEmptyZip: false,
  isUpdateZipOnChange: false,
  noUpdateOnBlur: false,
  isLocationAddressZip: false, // appraisal (EVAL) only, if true update location.address.zip otherwise update location.zip
  onZipChangeInProgress: null,
  inputAriaLabel: 'Zip Code',
  trackingCbOnZipChange: null,
  onZipFocus: noop,
  fireTrackingZipChange: null,
  btnColor: 'success',
  btnClassname: '',
  btnTrackingId: null,
  id: '',
  inputComponent: Input,
  inputProps: {},
  onInputChange: noop,
  zipCode: '',
  goText: 'Go',
  withGoBtn: false,
  withErrorText: false,
  errorTextClasses: undefined,
};

const getInitialZipCodeValue = ({ forceEmptyZip, zipFromStore, zipCode }) => {
  if (forceEmptyZip) return '';
  return zipCode || zipFromStore;
};

const ERROR_TEXT_ID = 'error-zip-code-input';

/**
 * @param {Function} onZipChange - a function to customize what happens when the zip code has been changed.
 *       This method is not same as DOM onChange event, do not be mislead! it's invoked when user changed
 *       zip and the new zip is validated
 * )
 * @param {Function} onValidate - a zip code validation function
 * @param {Function} onZipChangeInProgress -
 *
 *       callback that is passed a param ({ isZipChangeInProgress }) representing if a zip change is currently in-progress.
 *       this might be true for a number of reasons, including: the user is making a change, the zip is being
 *       validated by an api, the new zip is being saved to storage.  `isZipChangeInProgress` will be passed into
 *       the callback as a boolean. This callback can be leveraged to prevent page transition when zip update is in progress
 *
 *        example workflow for onZipChangeInProgress:
 *
 *        1. user is changing text
 *        2. clicks outside of of zip input -> component initiates XHR to trigger a zip update
 *        3. XHR completes, store is updated -> props.onZipChangeInProgress({isZipChangeInProgress: false}) is invoked
 */
export class ZipInputUI extends Component {
  static propTypes = {
    ...propTypes,
    setModelValue: PropTypes.func.isRequired,
    zipFromStore: PropTypes.string,
  };

  static defaultProps = {
    ...defaultProps,
    zipFromStore: '',
  };

  state = {
    isZipChangeInProgress: false,
    value: getInitialZipCodeValue(this.props),
    previousZipFromStore: this.props.zipFromStore, // should only be used in getDerivedStateFromProps
    previousForceEmptyZip: this.props.forceEmptyZip,
    validZip: true,
  };

  /**
   *  This method is used to overwrite the current value in the dom when the zipFromStore changes.
   *  Commonly occurs when two ZipInputs live on the same page, this is how we keep them in sync with each other.
   */
  static getDerivedStateFromProps(props, state) {
    if (state.previousZipFromStore !== props.zipFromStore) {
      return {
        value: props.zipFromStore, // set the input value to match the update from the store
        previousZipFromStore: props.zipFromStore, // update this so we can do the comparison if it changes again
      };
    } else if (state.previousForceEmptyZip && !props.forceEmptyZip) {
      // in the places where we show forceEmptyZip, we want it to be empty for as short a time as possible.
      // if at any point the prop for forceEmptyZip is changed from true -> false, we want to update the value
      // of the zip in the dom to match the store
      return {
        previousForceEmptyZip: false,
        value: props.zipFromStore, // set the input value to match the update from the store
        previousZipFromStore: props.zipFromStore, // update this so we can do the comparison if it changes again
      };
    }
    return null;
  }

  setZipChangeInProgress = ({ isZipChangeInProgress }) => {
    const { onZipChangeInProgress } = this.props;
    if (onZipChangeInProgress) {
      this.setState({ isZipChangeInProgress }, () => {
        onZipChangeInProgress({ isZipChangeInProgress });
      });
    }
  };

  setZipFromStore = () =>
    this.setState({ value: this.props.zipFromStore }, () => this.props.onInputChange(this.props.zipFromStore));

  setZipToModel = newZipCode => this.props.setModelValue('location', VisitorModel, { zipCode: newZipCode });

  setZipToAddressModel = newZipCode =>
    this.props.setModelValue('location.address', VisitorModel, { zipCode: newZipCode });

  /**
   * This is needed to support the "controlled" aspect of the zip-input.  This
   * is needed so that all zips on the page are up to date when one is updated.
   */
  handleChange = ({ target: { value: newZipCode } }) => {
    const { isZipChangeInProgress } = this.state;
    if (!isZipChangeInProgress) {
      this.setZipChangeInProgress({ isZipChangeInProgress: true });
    }

    const { isUpdateZipOnChange, onValidate, onInputChange } = this.props;
    onInputChange(newZipCode);
    this.setState({ value: newZipCode }, () => {
      if (isUpdateZipOnChange && onValidate(newZipCode)) {
        this.callUpdate();
      }
    });
  };

  handleZipCodeChange = (onZipChange, newZipCode) => {
    const {
      trackingCbOnZipChange,
      isLocationAddressZip,
      fireTrackingZipChange,
      withErrorText,
      zipFromStore,
    } = this.props;
    const onChangeHandler = onZipChange || (isLocationAddressZip ? this.setZipToAddressModel : this.setZipToModel);

    if (trackingCbOnZipChange) {
      trackingCbOnZipChange(newZipCode);
    }

    onChangeHandler(newZipCode)
      .catch(() => {
        if (zipFromStore) {
          this.setZipFromStore();
        } else {
          this.setState({ validZip: false });
        }
      })
      .finally(() => {
        this.setZipChangeInProgress({ isZipChangeInProgress: false });
        if (!isLocationAddressZip) {
          this.setZipToAddressModel(newZipCode);
        }
        if (withErrorText) {
          fireTrackingZipChange(newZipCode, TrackingConstant.USER_ACTION_CATEGORY);
        }
      });
  };

  /**
   * Handles enter key press.
   * @param {Object} el Event object
   */
  handleEnterPress = el => {
    if (el.keyCode === ENTER_KEY_CODE) {
      this.callUpdate();
    } else if (el.keyCode === ESC_KEY_CODE) {
      this.setZipFromStore();
      this.setZipChangeInProgress({ isZipChangeInProgress: false });
    }
  };

  /**
   * Calls onZipChange callback if value is valid.
   */
  callUpdate = () => {
    const { onZipChange, onValidate, zipFromStore, forceEmptyZip, fireTrackingZipChange, withErrorText } = this.props;
    const newZipCode = this.state.value;

    if (zipFromStore !== newZipCode && onValidate(newZipCode)) {
      this.setState({ validZip: true });
      this.handleZipCodeChange(onZipChange, newZipCode);
    } else if (newZipCode && !onValidate(newZipCode) && fireTrackingZipChange) {
      this.setState({ validZip: false });
    } else if (forceEmptyZip && zipFromStore === newZipCode) {
      // this case occurs when a user starts with a forced blank zip input. the user will already have a assigned
      // to them in the store (based on their IP or cookie).  if the user changes their blank input to the zip
      // that matches the one assigned to them by their ip, then `zipFromStore === newZipCode`.  When this
      // happens, we need to treat this as if the zip code has changed because the user has gone from '' -> 'XXXXX'.
      // If we do not treat it as if the zip has changed, then any code listening for a zip change using
      // props.onZipChange will not be notified that the user has made a change .
      this.handleZipCodeChange(onZipChange, newZipCode);
    } else if (forceEmptyZip && !newZipCode && fireTrackingZipChange) {
      // this case occurs when user does not input a zip and taps 'Go' button.
      this.setZipFromStore();
      this.handleZipCodeChange(onZipChange, zipFromStore);
      if (!withErrorText) {
        fireTrackingZipChange(zipFromStore);
      }
    } else if (zipFromStore === newZipCode) {
      // this case sets validZip to true after changing Zip Code and restoring it to the previous value (zipFromStore)
      this.setState({ validZip: true });
      this.setZipChangeInProgress({ isZipChangeInProgress: false });
    } else {
      // Notice that for this case we do not need to run `this.handleZipCodeChange` because we are not actually
      // "updating" the zip code. Instead we are resetting to the zipFromTheStore, which should have been
      // the last valid input in the zip input (because of the cases above).  This should only occur when
      // the zip is invalid or if the zip has not changed.
      this.setZipFromStore();
      this.setZipChangeInProgress({ isZipChangeInProgress: false });
    }
  };

  /**
   * Renders Input component.
   * @returns {ReactElement}
   */
  renderInput = inputId => {
    const {
      facetName,
      placeholder,
      inputClasses,
      getTracking,
      onZipChangeInProgress,
      inputSize,
      inputAriaLabel,
      isUpdateZipOnChange,
      noUpdateOnBlur,
      onZipFocus,
      inputComponent: InputComponent,
      inputProps,
      withErrorText,
    } = this.props;
    const { validZip } = this.state;
    const trackingConfig = (typeof getTracking === 'function' && getTracking(facetName)) || getTracking || {};

    return (
      <InputComponent
        id={inputId}
        name={facetName}
        bsSize={inputSize}
        type="text"
        inputMode="numeric"
        pattern="[0-9]*"
        value={this.state.value} /* controlled input to keep all zips on the page consistent */
        className={inputClasses}
        placeholder={placeholder}
        onKeyDown={this.handleEnterPress}
        onBlur={isUpdateZipOnChange || noUpdateOnBlur ? null : this.callUpdate}
        {...(onZipChangeInProgress ? { onFocus: onZipFocus } : {})}
        onChange={this.handleChange}
        data-test="zip-input"
        {...(withErrorText
          ? {}
          : {
              'data-tracking-id': trackingConfig ? trackingConfig['data-tracking-id'] : null,
              'data-tracking-value': trackingConfig ? trackingConfig['data-tracking-value'] : null,
            })}
        aria-label={inputAriaLabel}
        {...inputProps}
        {...(withErrorText ? { 'aria-invalid': !validZip, 'aria-describedby': ERROR_TEXT_ID } : {})}
      />
    );
  };

  render() {
    const {
      label,
      wrapperClasses,
      labelClasses,
      forceEmptyZip,
      fireTrackingZipChange,
      btnColor,
      btnClassname,
      btnTrackingId,
      id,
      goText,
      withGoBtn,
      withErrorText,
      errorTextClasses,
    } = this.props;
    const { validZip } = this.state;
    const inputId = id || `zip-input-${randomInt()}`;
    return (
      <Fragment>
        {label ? (
          <div className={wrapperClasses}>
            <Label for={inputId} className={labelClasses}>
              {label}
            </Label>
            {this.renderInput(inputId)}
          </div>
        ) : (
          <>
            <div className={classnames('d-flex', wrapperClasses, { 'has-danger': !validZip })}>
              {this.renderInput(inputId)}
              {fireTrackingZipChange && (forceEmptyZip || withGoBtn) && (
                <Button
                  onClick={this.callUpdate}
                  color={btnColor}
                  className={classnames('px-0_5 py-0_5 zip-button text-transform-none', btnClassname)}
                  style={{ width: '39px' }}
                  data-tracking-id={btnTrackingId}
                >
                  <span>{goText}</span>{' '}
                </Button>
              )}
            </div>
            {withErrorText && (
              <ErrorFieldCopy
                id={ERROR_TEXT_ID}
                className={classnames('text-danger small', errorTextClasses, { 'mt-0_25': !validZip })}
                copy={validZip ? '' : 'Please enter a valid ZIP code.'}
              />
            )}
          </>
        )}
      </Fragment>
    );
  }
}

export const stateToPropsConfig = {
  zipFromStore: bindToPath(
    ({ isLocationAddressZip, noZipFromStore }) =>
      !noZipFromStore && (isLocationAddressZip ? 'location.address.zipCode' : 'location.zipCode'),
    VisitorModel
  ),
};

export const ZipInput = connectToModel(ZipInputUI, stateToPropsConfig);
